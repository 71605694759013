<template>
  <div>
    <CircularProgress v-if="loader" />

    <v-app-bar absolute color="#434242" dark>
      <template>
        <v-icon dense class="mr-1 ml-1">$kiraakLogo</v-icon>
        <v-toolbar-title class="kiraakLogo">
          KIRAAK
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <span class="partnerLogo" v-if="orderData">{{ this.orderData.partnerBrandName }}</span>
      </template>
    </v-app-bar>

    <div class="pa-2 mt-16" v-if="orderData" align="center">
      <div class="mt-5">
        <p class="orderDetails">Order No: {{ this.orderData.orderSeriesNo }} - {{ this.orderData.orderStatus.toUpperCase() }}</p>
        <p class="orderDetails" v-if="orderData.orderPaymentDetails.status === 'paid'">PAID</p>
        <v-btn :href="orderData.orderPaymentDetails.paymentLink" class="paymentBtn" color="#409965" v-if="orderData.orderType === 'regular' && orderData.orderPaymentDetails.status === 'not_paid' && ['active', 'packaging', 'packaging_completed', 'dispatched', 'delivered'].includes(orderData.orderStatus)">Complete your Payment</v-btn>
        <v-simple-table class="mt-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Product
                </th>
                <th class="text-left">
                  Quantity
                </th>
                <th class="text-left">
                  Items
                </th>
                <th class="text-left">
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in orderData.cartList" :key="item._id">
                <td>{{ item.productId.productName }} / {{ item.productId.productDescription }}</td>
                <td>{{ item.productBaseQuantity }}</td>
                <td>{{ item.orderQuantity }}</td>
                <td>{{ item.productTotalBill }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="mt-5" v-if="orderData.orderPaymentDetails.refunds.length > 0">
          <p class="orderDetails">Refunds</p>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>Reason</th>
                  <th>Amount</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in orderData.orderPaymentDetails.refunds" :key="item._id">
                  <td>{{ item.reason }}</td>
                  <td>{{ item.amount }}</td>
                  <td>{{ item.status }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

        <p class="orderBillAmount mt-5">Total Bill: {{ this.orderData.orderPaymentDetails.amount }}</p>

        <p class="contactMessage mt-2">
          For any queries or assistance for your order please reach out to:
          <br>{{ this.orderData.partnerName }}<br>
          <a :href="WhatsAppLink()" class="contactLink">{{ this.orderData.partnerMobile }}</a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import partnerServices from '@/services/partnerServices'
import CircularProgress from './loaders/CircularProgress.vue'

export default {
  name: 'OrderStatus',
  components: {
    CircularProgress,
  },
  props: {

  },
  data () {
    return {
      loader: false,
      orderData: null
    }
  },
  methods: {
    WhatsAppLink(){
      return 'https://api.whatsapp.com/send/?text=Hi&phone=91' + this.orderData.partnerMobile
    }
  },
  async mounted() {
    this.loader = true
    try {
      const orderData = (await partnerServices.viewOrderDetails({'orderId': this.$route.params.orderId})).data
      this.orderData = orderData.customerOrderDetails[0]
    } catch(error) {
      console.log(error)
    }
    this.loader = false
  }
}
</script>
<style scoped>
.kiraakLogo {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 46px;
  color: #F2BA55;
}
.partnerLogo {
  font-family: 'Merienda', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 25px;
  color: #ffffe0;
}
.orderDetails {
  color: #FDFAF1;
  font-size: 20px;
}
.paymentBtn {
  font-family: Lato;
  font-style: normal;
  color: #FDFAF1 !important;
  font-size: 20px !important;
}
.orderBillAmount {
  color: #FDFAF1;
  font-size: 25px;
}
.contactMessage {
  color: #FDFAF1;
  font-size: 20px;
}
.contactLink {
  color: #FDFAF1;
  font-size: 25px;
}
</style>
